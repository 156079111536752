<template>
    <div>
        <TestComponent ref="component" :objType="{one: 'airline', multi: 'airlines'}"
                       @delete-submit="submitDeleteModal"
                       @edit-submit="submitEditModal"
                       @create-submit="submitCreateModal" @edit-show="showEditModal" @create-show="showCreateModal"
                       tableTitle="Manage Airlines"
                       :data="airlines"
                       nameField="name"
                       :formFields='[
      {name: "name", type: "text", required: true, label: "Name"},
    ]'
                       :orderBy="{column: 'name', ascending: true}"
                       :columns="['name', 'image', 'edit']"
                       :headings="{name: 'Airline',edit: 'Edit'}"
                       :sortable="['name']"
                       :filterable="['name']">

            <div slot="createModalInput">
                <label>Upload Airline Logo</label><br>
                <input v-if="upload.dataBlackhole.length === 0" type="file" ref="files"
                       v-on:change="handleFileUpload()"/>
                <div v-else>
          <span v-for="(image,index) in upload.dataBlackhole" :key="index">
            <span class="mb-2"><b>Source for "{{ image.name }}"</b></span>
          </span>
                </div>
            </div>
            <div slot="editModalInput">
                <label>Replace Airline Logo</label><br>
                <input v-if="upload.dataBlackhole.length === 0" type="file" ref="filesEdit"
                       v-on:change="handleFileUploadEdit()"/>
                <div v-else>
          <span v-for="(image,index) in upload.dataBlackhole" :key="index">
            <span class="mb-2"><b>Source for "{{ image.name }}"</b></span>
          </span>
                </div>
            </div>
        </TestComponent>
    </div>
</template>

<script>
import TestComponent from '../../admin/Component.vue';
import fcr from "@/services/fcr";
import blackhole from "@/services/blackhole";

export default {
    components: {
        TestComponent,
    },
    data: () => {
        return {
            airlines: [],
            upload: {
                dataBlackhole: '',
                ids: []
            },
        }
    },
    created() {
        this.getAirlines();
    },
    methods: {
        getAirlines() {
            fcr.readAirlines().then(response => {
                this.airlines = response.data;
            }, error => {
                console.error(error);
            });
        },
        handleFileUpload() {
            this.upload.dataBlackhole = this.$refs.files.files;
        },
        handleFileUploadEdit() {
            this.upload.dataBlackhole = this.$refs.filesEdit.files;
        },
        submitEditModal(airline) {
            if (this.upload.dataBlackhole.length === 0) {
                fcr.updateAirline(airline._id, airline).then(response => {
                    this.getAirlines();
                }, error => {
                    console.error(error);
                });
            } else {
                this.upload.ids = [];
                for (let i = 0; i < this.upload.dataBlackhole.length; i++) {
                    let formData = new FormData();
                    formData.append('file', this.upload.dataBlackhole[i]);
                    blackhole.createObject(formData).then(response => {
                        this.upload.ids.push(response.data);
                        if (this.upload.ids.length === this.upload.dataBlackhole.length) {
                            this.upload.dataBlackhole = '';
                            airline.image = this.upload.ids[0];
                            fcr.updateAirline(airline._id, airline).then(response => {
                                this.getAirlines();
                            }, error => {
                                console.error(error);
                            });
                        }
                    }).catch(error => {
                        this.upload.dataBlackhole = '';
                        this.$mToast({
                            title: error.response.status + ' Error',
                            text: "Image(s) couldn't be uploaded: " + error.response.statusText,
                            style: 'error'
                        });
                    });
                }
            }
        },
        submitDeleteModal(id) {
            fcr.deleteAirline(id).then(response => {
                this.getAirlines();
            }, error => {
                console.error(error);
            });
        },
        submitCreateModal(airline) {
            this.upload.ids = [];
            for (let i = 0; i < this.upload.dataBlackhole.length; i++) {
                let formData = new FormData();
                formData.append('file', this.upload.dataBlackhole[i]);
                blackhole.createObject(formData).then(response => {
                    this.upload.ids.push(response.data);
                    if (this.upload.ids.length === this.upload.dataBlackhole.length) {
                        this.upload.dataBlackhole = '';
                        airline.image = this.upload.ids[0];
                        fcr.createAirline(airline).then(response => {
                            this.getAirlines();
                        }, error => {
                            console.error(error);
                        });
                    }
                }).catch(error => {
                    this.upload.dataBlackhole = '';
                    this.$mToast({
                        title: error.response.status + ' Error',
                        text: "Image(s) couldn't be uploaded: " + error.response.statusText,
                        style: 'error'
                    });
                });
            }
        },
        showCreateModal() {
            this.upload.dataBlackhole = [];
            this.upload.ids = [];
        },
        showEditModal() {
            this.upload.dataBlackhole = '';
            this.upload.ids = [];
        },
        createEnterButton() {
            this.$refs.component.createEnterButton()
        },
        editEnterButton() {
            this.$refs.component.editEnterButton()
        }
    },
    computed: {
        /*editNotSubmittable(){
          for(let child of this.editChildTabs){
            for(let field in child){
              if(!child[field]){
                return true;
              }
            }
          }
          return false;
        },
        createNotSubmittable(){
          for(let child of this.createChildTabs){
            for(let field in child){
              if(child[field] ==="" || child[field]===undefined){
                return true;
              }
            }
          }
          return false;
        },*/
    }
}
</script>

<style scoped>

</style>